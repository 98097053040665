<template>
  <div>
    <account-credit :user-id="userId" :currency="currency" />

    <payment-methods
      :user-id="userId"
      context="select"
      @selected="$emit('cartdata', { paymentMethod: $event })"
    />
  </div>
</template>

<script>
export default {
  name: "SelectPaymentMethod",
  components: {
    "account-credit": () => import("@shared/account/_accountCredit"),
    "payment-methods": () => import("@shared/account/_paymentMethods")
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      default: () => {
        return null;
      }
    }
  }
};
</script>
